.paginationCon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.paginationCon .leftPagCon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.paginationCon .itemsText {
    color: #444444;
    font-size: 18px;
}

.paginationCon .MuiSelect-select {
    padding-top: 4px;
    padding-bottom: 4px;
    background: #fff;
}
.rightPageCon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.rightPageCon .leftArrowCon, .rightPageCon, .rightArrowCon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rightPageCon .centerPageCon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.rightPageCon .centerPageCon .currentPage {
    width: 37px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #A4A4A4;
    border-radius: 3px;
    background-color: #fff;
}
.rightPageCon .centerPageCon .lastPage {
    font-size: 16px;
    color: #444444;

}