@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@300&display=swap');
.user-row{
    padding-top:30px;
    justify-content: space-between;
}
.splash1{
   background: #546FE6 0% 0% no-repeat padding-box;
   height:83px;
   opacity:88%
}
#searcg-role{
   font-family: 'Poppins';
}
.add-user-circle{
   height: 35px;
   margin-top: -5px;
} 
.userrole-div{
   width: 70%;
   background-color: #546FE6;
    margin: 0px 70px 20px 70px; 
    border-radius: 30px;
    height:45px;
    padding:5px 0px
 }
 .user-role{
   
   color:white;
   text-align: center;
   font-size: 24px;
   cursor: pointer;
   font-family: 'Poppins-Semibold';

}

.user-role-par{
    color:#fff;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 24px;
    margin-top:-6px
}
.user-inner-col{
    margin:0px 20px
}
input[type="search"]  {
    border: none;
    outline: none;
    border-bottom: 1px solid #ccc; 
}
.add-user{
    background-color: white;
    padding-top:10px !important;
    color:#546FE6;
    font-size: 16px;
    padding:5px 10px;
    margin-right:30px;
    cursor: pointer;
    font-family: 'Poppins-SemiBold';
    height:46px;
    margin-top:-7px;
    border-radius:5px
}


.user-col{
    margin-right: 40px;
}
.avatar{
    width:35px;
    height:35px;
    border-radius: 50%;
}
.outer1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;    
}
.inner1 {
    width: 95%;
    margin:auto;
    margin-top:30px;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    height:800px;
    border-radius: 8px;
}

 .username{
      width: 100%;
    padding: 4px 0px;
    margin: 0px 0;
    box-sizing: border-box;
    border: 2px solid grey;
    border-width:0px 0px 2px 0px;
 }
 input[type=text]:focus {
    outline:none;
    border-image: linear-gradient(to right, #DEAFDB 0%, #EFB9AD 100%) 1;
    border-width:0px 0px 2px 0px;
 }
 .form-user{
    margin: 2px 40px;
 }
 .add-user-par{
    color:#546FE6;
    font-family: 'Montserrat-Bold';
    font-size: 28px;
    margin: 0px 10px 0px;
 }
 .user{
    font-family: 'Poppins';
    font-size: 12px;
    color:#63799B;
 }
 .username{
    font-family: 'Poppins';
    font-size: 13px;
    
 }
 .ast{
    color:red;
 }
 .active{
    background-color: green;
    color:#fff;
    font-size: 12px;
    margin-top:10px;
    padding:2px 15px;
    border-radius: 20px;
 }
 .inactive{
    background-color: #F2F5FA;
    font-size: 12px;
    margin-left:20px;
    margin-top:10px;
    padding:2px 15px;
    border-radius: 20px;
 }
 .user{
    margin-top:10px
 }
 .save{
    text-align: center;
    background-color: #546FE6;
    width: 100%;
    color: white;
    border-radius: 30px;
    border-color: #546FE6 !important;
    border: none !important;
    height: 40px;
    margin-top: 20px;
 }


 .search1{
    width:57vw;
    height:50px;
    padding-left: 40px;
    font-size: 12px;
    margin-top:-8px ;
    font-size: 16px;
   font-family: 'Poppins';
   border-radius: 5px;
   color:#63799B
 }
 .search1::placeholder{
   color:#63799B;
   
 }

 .sear-icon{
   position: absolute;
   padding-left: 10px;
   width:20.97px;
   height:20.97px;
   margin-top: 2px;
 }
 
 .plus-icon{
   margin-right: 5px;
 }
 .name{
   background:none;
   color: white;
   border: none;
   font-size:18px;
   font-family: 'Poppins-SemiBold';
   font-weight: bold;
   margin-top: -7px;
 }
 #patient-name{
   font-size: 8px !important;
   margin-left: 4px;
 }
 .active-table{
   color:#01CA54;
   border:1px solid #01CA54;
   width: 100px;
   text-align: center;
   border-radius: 30px;
   font-family: 'Poppins';
   font-weight: bold;
   font-size: 12px;
   padding:5px 0px 5px 0px ;
 }
 .inactive-table{
   color:#E47004;
   border:1px solid #E47004;
   width: 100px;
   text-align: center;
   border-radius: 30px;
   font-family: 'Poppins';
   font-weight: bold;
   font-size: 12px;
   padding:5px 0px 5px 0px ;
 }

 .active-table1{
   display: flex;
   flex-direction: column;
   background: #01CA54;
   color: white;
   width: 100px;
   height: 31px;
   border-radius: 15px;
   font-family: 'Poppins-Medium';
   font-size: 14px;
   justify-content: center;
   align-items: center;
 }

 .inactive-table1{
   display: flex;
   flex-direction: column;
   background: #E0E0E0 0% 0% no-repeat padding-box;
   color: #999999;
   width: 100px;
   height: 31px;
   border-radius: 15px;
   font-family: 'Poppins-Medium';
   font-size: 14px;
   justify-content: center;
   align-items: center;
 }
.nla-txt{
   font-size: 13px;
   color:White;
   margin-left: 4px;
   font-family: 'Poppins-Regular';
}
.table-action{
   align-items: center;
   justify-content: center;   
}
#user-pop{
   font-family:'Poppins-Regular';
   font-size: 13px;
}
#facility-usre{
   font-family:'Poppins-Regular';
   font-size: 16px;
}
#test{
   font-family:'Poppins-Regular';
   font-size: 13px;  
}
.select1 {
        
   color: #464A53; 
   font-size: 18px !important;
   font-family: 'Poppins-Regular !important'  
}