$bg_color : transparent linear-gradient(89deg, #FE6300 0%, #FFAF15 100%) 0% 0% no-repeat padding-box;


* {
  margin: 0;
  padding: 0;
  // box-sizing: border-box;
}


.top-menu {
  display: block;
  width: 100%;
  height: 58px;
  padding: 5px 0px;
  text-align: center;
  overflow: hidden;
}

.top-menu-item {
  display: flex;
  position: relative;
  justify-content: center;
  float: left;
  width: calc(100% / 4 - 10px);
  height: 58px;
  line-height: 50px;
  margin-right: 10px;
  padding: 0 10px;
  background: $bg_color;
  // border-radius: 4px;
}

.top-menu-item:before {
  content: "";
  position: absolute;
  right: -9px;
  height: 0;
  width: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
  border-left: 10px solid #FFAF15;
  border-radius: 4px;
}


.top-menu-item:after {
  content: "";
  position: absolute;
  left: -1px;
  height: 0;
  width: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
  border-left: 10px solid #F6F7FA;
  border-radius: 4px;
}

.top-menu-item:first-child:after {
  display: none;
}

.top-menu-item:last-child {
  margin-right: 0;
}

.top-menu-item:last-child:before {
  display: none;
}



.top-menu-item-inactive {
  display: flex;
  position: relative;
  justify-content: center;
  float: left;
  width: calc(100% / 4 - 10px);
  height: 58px;
  line-height: 50px;
  margin-right: 10px;
  padding: 0 10px;
  background: #fff;
  // border-radius: 4px;
}

.top-menu-item-inactive:before {
  content: "";
  position: absolute;
  right: -9px;
  height: 0;
  width: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
  border-left: 10px solid #fff;
  border-radius: 4px;
}


.top-menu-item-inactive:after {
  content: "";
  position: absolute;
  left: -1px;
  height: 0;
  width: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
  border-left: 10px solid #F6F7FA;
  border-radius: 4px;
}

.top-menu-item-inactive:first-child:after {
  display: none;
}

.top-menu-item-inactive:last-child {
  margin-right: 0;
}

.top-menu-item-inactive:last-child:before {
  display: none;
}


.section-title {
  padding: 0px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}