.mainLoader {
    /* height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; */
}
.loading {
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    border-top: 10px solid #e74c3c;
    position: relative;
    border-radius: 50%;
    animation: a1 2s linear infinite;

}
.loading::before, .loading::after {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    left: 0;
    top: -10px;
    box-sizing: border-box;
    border-radius: 50%;
}
.loading::before {
    border-top: 10px solid #e67e22;
    transform: rotate(120deg);
}
.loading::after {
    border-top: 10px solid #3498db;
    transform: rotate(240deg);
}
.loading span {
    position: absolute;
    width: 200px;
    height: 200px;
    line-height: 200px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    animation: a2 2s linear infinite;
}
.loading span:nth-child(2){
    padding-top: 25px;
  }
@keyframes a1 {
    to {
        transform: rotate(360deg);
    }
}

@keyframes a2 {
    to {
        transform: rotate(-360deg);
    }
}