@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@300&display=swap');

.splash {
    height:100vh;
    background-image: url('/src/assets/images/splash-bg.png');
    background-attachment: fixed;
    background-size: 100% 100%;
}


.main-box{
    height:94vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.main-box svg {
  max-width: 570px;
}

.footer{
    height:6vh; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

/* .loader {
    margin-top: 40px;
    transform: scale(0.5);
    display: inline-block;
    height: 1em;
    width: 1em;
    line-height: 1;
    vertical-align: middle;
    border-radius: 1em;
    transition: all 150ms linear 0s;
    color: #1E4D92;
    box-shadow: -1.30972146790179em
        1.5114991486987945em 0
        -0.07291666666666666em,-1.918985947234223em
        0.5634651136650534em 0
        -0.07291666666666666em,-1.9189859472227206em
        -0.5634651137042269em 0
        -0.07291666666666666em,-1.3097214678709352em
        -1.5114991487255303em 0
        -0.07291666666666666em,-0.2846296765171809em
        -1.979642883766091em 0
        -0.07291666666666666em,0.8308300260341593em
        -1.8192639906951598em 0
        -0.07291666666666666em,1.6825070656824284em
        -1.0812816348799716em 0
        -0.07291666666666666em;
    animation: spinDots 1700ms infinite steps(11);
  }
  @keyframes spinDots {
    0% {
      transform: scale(1.2) rotate(0);
      animation-timing-function: cubic-bezier(.55,.055,.675,.19);
    }
    50% {
      transform: scale(1.2) rotate(180deg);
      animation-timing-function: cubic-bezier(.55,.055,.675,.19);
    }
    100% {
      transform: scale(1.2) rotate(360deg);
    }
  } */

  @keyframes ldio-oauhw8tti3l {
    0% {
      opacity: 1;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1.5,1.5);
    } 100% {
      opacity: 0;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1.5,1.5);
    }
  }
  .ldio-oauhw8tti3l div > div {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #0c8ad8;
    animation: ldio-oauhw8tti3l 1s linear infinite;
  }.ldio-oauhw8tti3l div:nth-child(1) > div {
    left: 148px;
    top: 88px;
    animation-delay: -0.875s;
  }
  .ldio-oauhw8tti3l > div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 160px 100px;
  }.ldio-oauhw8tti3l div:nth-child(2) > div {
    left: 130px;
    top: 130px;
    animation-delay: -0.75s;
  }
  .ldio-oauhw8tti3l > div:nth-child(2) {
    transform: rotate(45deg);
    transform-origin: 142px 142px;
  }.ldio-oauhw8tti3l div:nth-child(3) > div {
    left: 88px;
    top: 148px;
    animation-delay: -0.625s;
  }
  .ldio-oauhw8tti3l > div:nth-child(3) {
    transform: rotate(90deg);
    transform-origin: 100px 160px;
  }.ldio-oauhw8tti3l div:nth-child(4) > div {
    left: 46px;
    top: 130px;
    animation-delay: -0.5s;
  }
  .ldio-oauhw8tti3l > div:nth-child(4) {
    transform: rotate(135deg);
    transform-origin: 58px 142px;
  }.ldio-oauhw8tti3l div:nth-child(5) > div {
    left: 28px;
    top: 88px;
    animation-delay: -0.375s;
  }
  .ldio-oauhw8tti3l > div:nth-child(5) {
    transform: rotate(180deg);
    transform-origin: 40px 100px;
  }.ldio-oauhw8tti3l div:nth-child(6) > div {
    left: 46px;
    top: 46px;
    animation-delay: -0.25s;
  }
  .ldio-oauhw8tti3l > div:nth-child(6) {
    transform: rotate(225deg);
    transform-origin: 58px 58px;
  }.ldio-oauhw8tti3l div:nth-child(7) > div {
    left: 88px;
    top: 28px;
    animation-delay: -0.125s;
  }
  .ldio-oauhw8tti3l > div:nth-child(7) {
    transform: rotate(270deg);
    transform-origin: 100px 40px;
  }.ldio-oauhw8tti3l div:nth-child(8) > div {
    left: 130px;
    top: 46px;
    animation-delay: 0s;
  }
  .ldio-oauhw8tti3l > div:nth-child(8) {
    transform: rotate(315deg);
    transform-origin: 142px 58px;
  }
  .loadingio-spinner-spin-9r77fcqv1dg {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
  }
  .ldio-oauhw8tti3l {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-oauhw8tti3l div { box-sizing: content-box; }