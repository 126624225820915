@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@300&display=swap');

.icons-row {
  margin-top: 60px;
  flex-wrap: wrap;
  align-items: center;
}

.icons-row-roche {
  margin-top: 60px;
  flex-wrap: wrap;
  align-items: center;
}

.inner-col {
  box-shadow: 0px 10px 30px #D1D5DF80;
  padding: 30px 20px;
  margin: 10px 25px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #D3DDEA;
  width: 260px;
  height: 220px;
}
.inner-col:hover {
  box-shadow: 0px 10px 30px #D1D5DF80;
  padding: 30px 20px;
  margin: 10px 25px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #D3DDEA;
  width: 260px;
  height: 220px;
  background: #f0f8fb;
}

.inner-col1 {
  box-shadow: 0px 10px 30px #D1D5DF80;
  padding: 30px 20px;
  margin: 0px 13px 0px -15px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #D3DDEA;

  /* width: 300px;
height: 251px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 10px 30px #D1D5DF80;
border: 1px solid #D3DDEA;
border-radius: 20px;
opacity: 1; */
}

.exit-par {
  text-align: right;
  color: #546FE6;
  margin-top: 80px;
  font-size: 25px;
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Poppins-Bold'
}

.select {
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #110345 !important;
  font-size: 25px;
  /* margin-top: 80px; */
}

.user-role {
  text-align: center;
  color: white;
  border-radius: 30px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Poppins-Semibold'

}

.facility {
  text-align: center;
  width: 60%;
  /* background-color: #546FE6; */
  margin: 20px 70px;
  padding: 10px 20px;
  color: white;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 68px;

}

.delete-icon {
  margin-left: 10px !important;
  cursor: pointer;
}

.edit-icon {
  margin-top: 1px !important;
  cursor: pointer;
}

.table-row {
  color: #63799B !important;
  font-size: 14px;
  font-family: 'Montserrat';

}

#name {
  font-size: 14px;
  font-family: 'Poppins'
}

.delete-user {
  font-size: 14px;
  font-family: 'Poppins'
}

.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 17px
}

#delete-user {
  margin-top: 20px;
  text-align: center;
  font-family: 'Poppins';
  color: #434343;
  margin: 20px 20px 0px 20px
}

#facility {
  background-color: #546FE6;
  color: #fff;
  font-family: 'Poppins';
}

#delete {
  margin-top: 35px;
}

.yes-facility {
  text-align: center;
  background-color: #546FE6;
  color: #fff;
  font-family: 'Poppins';
  margin-top: 20px;
  border-radius: 20px;
  width: 70%;
  height: 35px;
  padding-top: 5px;
  margin-left: 45px
}

.inner-col-credential {
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 220px;
  height: 150px;
}

.cred-image {
  background-color: #FDA945;
  width: 75px;
  height: 50px;
  border-radius: 5px;
  padding: 7px;
}

.credential-par {
  margin-top: 20px;
  font-family: 'Poppins';
  font-size: 14px;
}

.select-category {
  padding-top: 40px
}

.row-icon-pop {
  margin-top: 23px
}

.setting-par {
  font-size: 22px;
  font-family: 'Poppins-Regular';
  margin-top: 16px;
  color: #141821;
}

.nla-img {
  width: 80px;
  height: 80px;
  margin-top: 10px
}

.switch {
  position: relative;
  display: inline-block;
  width: 95px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E0E0E0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}


.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  margin-left: 40px
}

input:checked+.slider {
  background-color: #01CA54;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 80px;
}

.slider1.round {
  border-radius: 80px;
}

.slider1.round:before {
  border-radius: 50%;
}

.slider.round:before {
  border-radius: 50%;
}

.txt {
  padding-left: 20px;
  color: #fff;
  font-size: 12px;
  font-family: 'Poppins';
}

.txt-in {
  padding-left: 30px;
  color: #999999;
  font-size: 12px;
  font-family: 'Poppins';
}