@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@font-face {
  font-family: Poppins-Regular;
  src: url(../font/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../font/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../font/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(../font/Poppins/Poppins-Bold.ttf);
}


@font-face {
  font-family: Montserrat-Regular;
  src: url(../font/Montserrat/static/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(../font/Montserrat/static/Montserrat-Bold.ttf);
}

@font-face {
  font-family: Montserrat-Medium;
  src: url(../font/Montserrat/static/Montserrat-Medium.ttf);
}