@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@300&display=swap');

.mainContainer{
  display: flex;
  flex-direction: column;
  background-color: #F6F7FA;
}
.header {
    background-color: #FFFFFF;
    height: 159px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 40px;
  }
  .logo1{
    width: 184px;
    height: 88px;
  }
  .logo2 {
    width: 140px;
    height: 88px;
  }
  .headerText{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
  }
  .report-title {
    font-family: "Poppins-Regular";
    font-size: 35px !important;
    color: #554886;
  }
  .reportRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 546px;
    margin-top: 8px;
    align-items: center;
  }
  .reportText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
  }
  .reportText1 {
    font-family: 'Poppins-Regular';
    font-size: 12px !important;
    color: #959595;
  }
  .reportText2 {
    font-family: 'Poppins-Medium';
    font-size: 20px !important;
    color: #554886;
  }
  .verticalLine {
    content: "";
    display: block;
    width: 1px;
    background-color: #707070;
    height: 45px;
  }
  .section {
    display: flex;
    flex-direction: column;
    background-color: #F6F7FA;
    height: 800px;
}
.overview {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 12px;
  margin-top: 36px;
  justify-content: space-between;
}
.overviewTime {
  width: 366px;
}
.overviewRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.overviewTitle {
  font-size: 24px !important;
  font-family: 'Poppins-Regular';
  color: #554886;
}
.overviewBox {
  width: 283px;
  height: 69px;
  border: 1px solid #AAAAAA;
  border-radius: 8px;
  opacity: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.overviewBox1 {
  width: 248px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}
.overviewBoxHead {
  font-size: 24px !important;
  color: #554886;
  font-family: 'Poppins-Medium';
  padding-left:4px;
}
.overviewBox12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.arrowUp {
  width: 15px;
  height: 8px;
}
.textUp {
  font-family: 'Poppins-Medium';
  font-size: 24px !important;
  color: #E65656;
}
.overviewBox2 {
  font-family: "Poppins-Regular";
  font-size: 10px !important;
  color: #686868;
}
.timeUpdate {
  font-family: "Poppins-Regular";
  font-size: 16px !important;
  color: #939393;
}