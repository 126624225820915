@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@300&display=swap');

.select-row {
    justify-content: space-between;
}

.sel {
    font-size: 28px;
    color: #5B59DF;
    font-family: 'Poppins-Bold';
}

.ex {

    font-size: 25px;
    color: #5B59DF;
    font-family: 'Poppins-Bold';
    text-decoration: underline;
    cursor: pointer;
}

.inner1 {
    /* width: 1400px; */
    margin: auto;
    /* margin-top: 58px; */
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    height: 625px;
    margin-bottom: 60px
}

.select-country-row {
    align-items: center;
}

.ser {

    height: 42px;
    border-radius: 8px;
    margin-left: 40px;
    background-color: #F1F8F8;
    border: none !important;
    color: #000;
    font-family: 'Poppins';
    padding-left: 20px;
    width: calc(100% - 50px);
}
/* .ser::placeholder {
    font-size: 16px;
    font-family: "Poppins-Regular";
    color: #63799B;
} */

.ser-div {
    border: 1px solid #dce4ee;
    background-color: #F1F8F8;
    opacity: 1;
    margin-left: 0px;
    border-radius: 4px;
    position: relative;
}
.select-countryflag .MuiOutlinedInput-notchedOutline, .select-countryflag:hover {
    border: 1px solid #dce4ee !important;
}

.sel-op {
    margin-left: 30px;
    width: 20vw;
    background-color: #F1F8F8;
    border: 1px solid #F1F8F8;
    padding: 5px 10px;
    font-family: 'Poppins';
    font-size: 14px;
}

.drop-roche {
    margin-left: 20px;
}

.country {
    margin-top: 30px;
}

.us-flag {
    width: 50px;
    height: 50px
}

.country-par {
    font-size: 14px;
    margin-left: 10px;
    font-weight: bold;
    font-family: 'Poppins';
    margin-bottom: 10px;
}

.label-font{
    font-size: 16px;
    font-weight: bold;
    font-family: 'Montserrat';
    color: #546FE6;
}


.con-par {
    font-size: 11px;
    margin-left: 10px;
    line-height: 0.1px;
    font-family: 'Poppins-Regular';
    color: #747272
}

.flags-row {
    margin-top: 35px
}

.horizontal {
    margin-top: -5px
}

.row-col-flag {
    cursor: pointer;
}

#ser-icon-roche {
    width: 21px;
    height: 21px;
    position: absolute;
    left: 10px;
    top: 6px;
}


.flags-roche {
    /* width: 53px;
    height: 36px; */
     object-fit: cover;
     width: 50px;
     height: 50px;
     border-radius: 50%;
}

.inner-col-roche {
    /* box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 30px 20px;
    margin:0px 10px 0px 10px;
    border-radius:25px;
    justify-content: center;
    align-items: center;
     */
    cursor: pointer;
    width: 300px;
    height: 251px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #D1D5DF80;
    border: 1px solid #D3DDEA;
    border-radius: 20px;
    opacity: 1;
}

.nla-img-roche {
    width: 101px;
    height: 101px;
    margin-top: 35px;
    margin-left: 92px;
    opacity: 1;
}

.setting-par-roche {
    font-size: 22px;
    color: #141821;
    font-family: 'Poppins-regular';
    text-align: center;
    margin-top: 16px
}

/* @media screen and (max-width:800px) {
    .ser{
        width:45vw;  
        
    }
    
}

@media screen and (max-width:500px) {
    .ser{
        width:45vw;    
    }
} */