.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index:4;
}
.facility-box{
  position: relative;
  width: 28%;
  margin: 0 auto;
  height: 49%;
  max-height: 80vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  border-radius: 25px;
  margin-top:170px
}
.facility-close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(37% - 21px);
  top: calc(100vh - 85vh - 33px);
  background: #FDA945;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
  border: 2px solid #fff;
  font-size: 20px;
  color:#fff;
  margin-top:85px

}
.box {
  position: relative;
  width: 535px;
  margin: 0 auto;
  height: 626px;
  max-height: 80vh;
  margin-top: calc(88vh - 76vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  border-radius: 25px;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(35% - 70px);
  top: calc(85vh - 75vh - 33px);
  background: #FDA945;
  width: 61.58px;
  height: 61.58px;
  border-radius: 50%;
  line-height: 45px;
  text-align: center;
  border: 2px solid #fff;
  font-size: 38px;
  color: #fff;
}
.box1 {
  position: relative;
  width: 30%;
  margin: 0 auto;
  height: 50%;
  max-height: 80vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  border-radius: 25px;
  margin-top:160px
}
.close-icon1 {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(35% - 0px);
  top: calc(100vh - 84vh - 33px);
  background: #FDA945;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
  border: 2px solid #fff;
  font-size: 20px;
  color:#fff;
  margin-top: 68px;
}
.box2 {
  position: relative;
  width: 20%;
  margin: 0 auto;
  height: 43%;
  max-height: 80vh;
  margin-top: calc(100vh - 80vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  border-radius: 25px;
  margin-top:190px;
  /* backdrop-filter: blur(8px); */

}
.close-icon2 {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(38% - -23px);
  top: calc(100vh - 80vh - 33px);
  background: #FDA945;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
  border: 2px solid #fff;
  font-size: 20px;
  color:#fff;
  margin-top: 73px;
}