@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@300&display=swap');

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  height: 100vh;
}

.inner {
  margin: auto;
  min-width: 1400px;
  height: 80%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 4px 54px #0000001C;
  opacity: 1;
  /* margin-top: 100px; */
}

.inner-login {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 40px 0px 40px;
  transition: all .3s;
  /* overflow-y: scroll; */
  /* height: 580px; */
}

.inner-for {
  /* width: 1300px; */
  margin: auto;
  /* margin-top: 65px; */
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 40px 45px 40px;
  transition: all .3s;
  overflow-y: scroll;
  /* height: 580px; */
}

.inner-for2 {
  /* width: 1300px; */
  margin: auto;
  /* margin-top: 65px; */
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 40px 45px 40px;
  transition: all .3s;
  overflow-y: scroll;
  height: 90%;
}



.inner-for-nla {
  margin: auto;
  /* margin-top: 65px; */
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  transition: all .3s;
  /* height: 600px; */
}

.inner-for-roche {
  /* width: 1300px; */
  margin: auto;
  /* margin-top: 65px; */
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  transition: all .3s;
  /* height: 580px; */
}


.login-par {
  font-size: 48px;
  color: #546FE6;
  text-align: center;
  font-family: 'Montserrat-Bold';

}

.login-par-mobile {

  font-size: 48px;
  color: #546FE6;
  text-align: center;
  font-family: 'Montserrat-Bold';
}

.login-innerpar {
  color: #403d3d;
  text-align: center;
  font-size: 18px;
  font-family: 'Poppins-Regular';
  margin-right: 3px
}

.login-innerpar1 {
  color: #999999;
  text-align: center;
  font-size: 22px;
  font-family: 'Poppins-Regular';
  margin-right: 3px;
  margin-bottom: 40px
}

.login-innerpar2 {
  color: #999999;
  text-align: center;
  font-size: 24px;
  font-family: 'Poppins-Regular';
  margin-right: 3px;

}

.input-icons {
  margin-top: 30px;
}

.form-field {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
}

.form-input-field {
  border: none !important;
  background: #F1F8F8;
  width: 570px;
  height: 65px;
  border-radius: 35px;
  justify-content: center;
}

.textfield-icon {
  display: flex;
  flex-direction: row;
  padding: 0px 26px 0px 61px;
  align-items: center;
}

.input-field {
  width: 570px;
  height: 60px;
  padding: 12px 20px;
  background: #F1F8F8 0% 0% no-repeat padding-box;
  border-radius: 35px;
  opacity: 1;
  border: none !important;
}

.input-field:hover {
  border: none;
}

.input-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1;
  /* Firefox */
}

.inner-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#lock {
  margin-top: 20px
}

.login-btn {
  background-color: #FDA945;
  opacity: 1;
  color: white;
  padding: 12px 60px;
  border: none;
  cursor: pointer;
  width: 570px;
  height: 75px;
  opacity: 0.9;
  margin-top: 25px;
  border-radius: 40px;
  margin-top: 25px;
  font-size: 27px;
  font-family: 'Poppins-Semibold';
  padding: 0px
}

.inner-forget {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  justify-content: center;
  margin-bottom: 20px
}

.inner-inner {
  display: flex;
  flex-direction: row;
  width: 550px;
  justify-content: space-between;
}

#forget-form {
  margin-top: 50px
}

.remember {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 11px
}

.label {
  font-size: 17px;
  font-family: 'Poppins-Regular';
  padding-top: 5px;
  color: #2F2C2C
}

.forgot-par {
  font-size: 17px;
  margin-left: 50px;
  color: #00ADEE;
  cursor: pointer;
  margin-top: 12px;
  font-family: 'Poppins-Regular';
  padding-top: 3px;
}

.check {
  width: 17px;
  height: 17px;
  margin-top: 6px
}


.back-login {
  color: #00ADEE;
  font-size: 20px;
  font-family: 'Poppins-Medium';

}

.back-img {
  margin-top: 5px;
  margin-right: 10px;
}

.forgot-row {
  margin-top: 40px;
  cursor: pointer
}

.email {
  margin-top: 10px;
  margin-left: 66px;
  margin-right: 26px;
}

.inputfield-otp {
  width: 100%;
  height: 78px;
  display: flex;
  justify-content: center;
}

.input-otp {
  width: 87px;
  text-align: center;
  font-size: 1.5em;
  border-radius: 0.3em;
  background-color: #E2F3F3;
  margin: 40px 10px;
}


.receive {
  margin-top: 20px;
  font-size: 17px;
  font-family: 'Poppins-Regular';
  text-align: center;
  color: #464A53;
}

.resend-txt {
  margin-left: 10px;
  color: #00ADEE;
  cursor: pointer;
}

.password-reset {
  text-align: center;
  font-size: 24px;
  font-family: 'Montserrat';
  color: #546FE6;
  font-weight: bold;

}

.your-password {
  color: #999999;
  font-size: 14px;
  font-family: 'Poppins';
  text-align: center;
}

.forgot-form {
  margin-top: 35px
}

.password-reset-row {
  margin-top: 30px
}

.form-login {
  /* margin-top: 60px */
}

/* #for {
  margin-left: 236px
} */

#pass-btn {
  margin-top: 27px;
  font-family: 'Poppins-SemiBold';
}

.arr-let {
  margin-top: 2px;
}

.form-otp {
  margin-top: 18px
}

#reset-btn {
  margin-top: 20px;
  font-family: 'Poppins-SemiBold';
}

.inner-passw {
  margin-top: 27px
}

#con-btn {
  font-family: 'Poppins';
  width: 33%
}

.tick-img {
  width: 100px;
  height: 70px;
  margin-top: 40px
}

.login-pass {
  margin-top: 30px
}

.tel-img-box{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tel-img {
  width: 104px;
  height: 104px;
}





@media screen and (min-width:1200px) {
  .inner-for {
    width: 900px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    /* height: 600px; */
    overflow-y: scroll;
  }
}

@media screen and (min-width:1350px) {
  .inner-for {
    width: 1000px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    /* height: 600px; */
    overflow-y: scroll;
  }
}

@media screen and (min-width:1420px) {
  .inner-for {
    width: 1400px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    /* height: 600px; */
    overflow-y: scroll;
  }
}

@media screen and (min-width:1920px) {
  .inner-for {
    width: 1400px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    /* height: 600px; */
    overflow-y: scroll;
  }
}

@media screen and (min-width:1200px) {
  .inner-for2 {
    width: 900px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    /* height: 600px; */
    overflow-y: scroll;
  }
}

@media screen and (min-width:1350px) {
  .inner-for2 {
    width: 1000px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    /* height: 600px; */
    overflow-y: scroll;
  }
}

@media screen and (min-width:1420px) {
  .inner-for2 {
    width: 1400px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    /* height: 600px; */
    overflow-y: scroll;
  }
}

@media screen and (min-width:1920px) {
  .inner-for2 {
    width: 1400px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    /* height: 600px; */
    overflow-y: scroll;
  }
}

@media screen and (min-width:1200px) {
  .inner-for-roche {
    width: 900px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
    height: 600px;
    overflow-y: scroll;
  }
}

@media screen and (min-width:1350px) {
  .inner-for-roche {
    width: 1000px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
  }
}

@media screen and (min-width:1420px) {
  .inner-for-roche {
    width: 1200px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
  }
}



/* Login  */

@media screen and (max-width:660px) {
  .inner-login {
    width: calc(100% - 70px);
    margin: auto;
    /* background: #ffffff; */
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 40px 0px 40px;
    transition: all .3s;
    /* height: 600px; */
    overflow-y: scroll;
  }

  .form-input-field {
    width: calc(100% + 60px);
  }

  .textfield-icon {
    padding: 0px 10px 0px 10px;
  }

  .login-btn {
    background: #FDA945;
    opacity: 1;
    color: white;
    padding: 12px 60px;
    border: none;
    cursor: pointer;
    width: calc(100% + 60px);
    height: 75px;
    opacity: 0.9;
    margin-top: 25px;
    border-radius: 40px;
    margin-top: 25px;
    font-size: 24px;
    font-family: 'Poppins-Semibold';
    padding: 0px
  }

  .inner-inner {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    width: calc(100% + 60px);
    justify-content: space-between;
  }

  .input-otp {
    width: calc(50% - 100px);
    text-align: center;
    font-size: 1.5em;
    border-radius: 0.3em;
    background-color: #E2F3F3;
    margin: 40px 10px;
  }
}

@media screen and (min-width:1200px) {
  .inner-login {
    width: 1000px;
    /* overflow-y: scroll; */
  }
}

@media screen and (min-width:1350px) {
  .inner-login {
    width: 1000px;
    /* overflow-y: scroll; */
  }
}

@media screen and (min-width:1420px) {
  .inner-login {
    width: 1200px;
    /* overflow-y: scroll; */
  }
}

@media screen and (min-width:1920px) {
  .inner-login {
    width: 1400px;
    /* overflow-y: scroll; */
  }
}

/* Login  */

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb00;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: lightgray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}