@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@300&display=swap');


.insight {
    background-color: white;
    color: #546FE6;
    font-size: 19px;
    /* padding:5px 10px; */
    margin-right: 30px;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Poppins-Semibold';
    height: 50px;
    width: 207px;
}

.insight-icon {
    margin-right: 10px;
}

.grid-box {
    border-radius: 10px;
    background-color: white;
    padding: 16px 30px;
    display: flex;
    flex-direction: column;
}

.navigate-details {
    background: #7D90E3;
    text-transform: none;
    padding: 4px 8px;
    font-size: 14px;
    font-family: 'Poppins-Medium';
    color: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
}

.hr-row {
    width: 100%;
    height: 12px;
    border: none;
    border-bottom: 0.5px dashed #e2e1e1;
}

.text-head {
    font-family: "Poppins-Regular";
    font-size: 12px;
    color: #BFBFBF;
}

.text-detail {
    font-family: "Poppins-Medium";
    font-size: 13px;
    color: '#434343'
}

.progressbar {
    height: 8px !important;
    width: 70% !important;
    background: transparent;
    margin: 0 !important;
    border-radius: 0px;
}

.progress-barriers {
    height: 5px !important;
    background: transparent;
    margin: 0 !important;
    width: 72px !important;
}


.progressbar1 {
    height: 15px !important;
    width: 210px !important;
    border-radius: 0;
    margin: 0 !important;
}

.bg-progress1 {
    background-color: #62E89C;
}

.bg-progress2 {
    background-color: #F57C7C;
}

.navigate-all {
    font-size: 13px;
    font-family: "Poppins-semiBold";
    color: #7D90E3;
    border-bottom: 1px solid #7D90E3;
    display: inline;
    cursor: pointer;
}

.search-box {
    font-size: 16px;
    color: #63799B;
    font-family: "Poppins-Regular";
    /* width: 50vw; */
    width: 100%;
    height: 50px;
    padding-left: 44px;
    font-size: 12px;
    border: 1px solid #F0F3F8;
    border-radius: 5px;
    background-color: white;
}

.search-search{
    width: 50vw;
    position: relative;
}

.search-box{
    padding-right: 8px;
}

.search-input {
    font-size: 16px;
    font-family: "Poppins-Regular";
    color: #63799B;
}


/* .search-search {
    position: relative;
} */

.search-icon {
    position: absolute;
    padding-left: 10px;
    width: 20.97px !important;
    height: 20.97px !important;
    margin-top: 10px;
    z-index: 1;
}

.search-box::placeholder {
    font-size: 16px;
    font-family: "Poppins-Regular";
    color: #63799B;
}

.section-container {
    display: grid;
    /* grid-template-columns: repeat(5, 1fr); */
    /* grid-template-rows: repeat(auto-fill, 120px); */
    /* grid-row-gap: .5em;
    grid-column-gap: 1em; */
}

@media screen and (min-width:360px) {
    .section-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (min-width:800px) {
    .section-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width:1300px) {
    .section-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width:1500px) {
    .section-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (min-width:1900px) {
    .section-container {
        grid-template-columns: repeat(5, 1fr);
    }
}
