@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@300&display=swap');
.pn-row{
    margin:30px 40px 20px 40px
}
.user-role-par1{
    color:#fff;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 20px;
    
}
.reg-par{
    color:#464A53;
    font-family: 'Poppins';
    font-size: 12px;
}
.reg{
    width:25vw;
    border:white;
    height:40px;
    box-shadow: 0px 14px 80px rgba(80, 35, 58, 0.2);
    top:-10px;
    margin-right:20px;
    font-size: 14px;
    padding-left: 20px;
    font-family: 'Poppins';
}
.Go{
    width:70px;
    margin-top:18px;
    color: #fff;
    background-color: #546FE6;
    border:none;
    height:35px;
    border-radius: 4px;
    font-family: 'Poppins';
}
/* .row-tr-pnp{
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
} */
.tab-pag{
    display: flex;
    justify-content: left;
}
.m-table{
    margin:16px 60px 0px 60px;
}