.referrals {
    width: 952px;
}

/* @media screen and (max-width:770px) {
    .referrals {
        max-width: 530px;
    }
} */

@media screen and (max-width:960px) {
    .referrals {
        max-width: 530px;
    }
}